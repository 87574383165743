<template>
  <svg
    enable-background="new 0 0 50 50"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    width="50px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="person-icon unknown-gender"
  >
    <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1" />
    </g>
    <g>
      <rect id="svg_1" width="50" height="50" fill="none" />
      <path
        class="shape"
        fill="none"
        stroke-width="2"
        id="svg_2"
        d="m30.933,32.528c-0.146,-1.612 -0.09,-2.737 -0.09,-4.21c0.73,-0.383 2.038,-2.825 2.259,-4.888c0.574,-0.047 1.479,-0.607 1.744,-2.818c0.143,-1.187 -0.425,-1.855 -0.771,-2.065c2.3599,-8.16178 -2.48825,-12.62375 -8.08835,-12.70439c-6.11207,0.24365 -10.00265,5.23439 -8.06165,12.70439c-0.345,0.21 -0.913,0.878 -0.771,2.065c0.266,2.211 1.17,2.771 1.744,2.818c0.22,2.062 1.58,4.505 2.312,4.888c0,1.473 0.055,2.598 -0.091,4.21c-1.752,4.71 -13.573,3.388 -14.119,12.472l38,0c-0.545,-9.084 -12.315,-7.762 -14.067,-12.472z"
      />
      <text
        class="symbol"
        transform="matrix(0.607621371746063,0,0,0.607621371746063,9.835875812917946,5.935198514722287) "
        xml:space="preserve"
        text-anchor="start"
        font-family="Arvo, sans-serif"
        font-size="29"
        id="svg_4"
        y="29.790043"
        x="19.116865"
        fill-opacity="null"
        stroke-opacity="null"
        stroke-width="0"
      >
        ?
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UnknownPersonIcon',
};
</script>

<style scoped lang="scss">
.person-icon {
  .shape {
    stroke: black;
    fill: black;
    fill-opacity: 0;
  }
  .symbol {
    fill: black;
    opacity: 0;
  }
}
</style>
