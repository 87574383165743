<template>
  <stand-out-block class="engaging-stand-out-block" :style="{'align-items': flexAlignVertical}">
    <h5 class="title">{{ title }}</h5>
    <div class="call-to-action">
      <div :class="{'has-margin-bottom': $slots.default}">
        <slot name="button" :loading="buttonLoading" :label="buttonLabel" :click="click">
          <mcr-button :loading="buttonLoading" @click="click" rel="nofollow">{{ buttonLabel }}</mcr-button>
        </slot>
      </div>
      <slot></slot>
    </div>
  </stand-out-block>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

export default {
  props: {
    title: String,
    buttonLabel: String,
    buttonLoading: Boolean,
    alignVertical: {type: String, default: 'top', validator: value => ['top', 'center'].includes(value)},
  },
  computed: {
    flexAlignVertical() {
      const mapping = {
        top: 'flex-start',
        center: 'center',
      };
      return mapping[this.alignVertical];
    },
  },
  methods: {
    click() {
      this.$emit('click', {buttonLabel: this.buttonLabel});
    },
  },
  components: {StandOutBlock, McrButton},
};
</script>

<style lang="scss" scoped>
.engaging-stand-out-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 40px;

  .title {
    margin-bottom: 0;
  }

  .call-to-action {
    flex-shrink: 0;
    margin-left: 15px;

    .has-margin-bottom {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    .call-to-action {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile-se) {
    padding: 24px;
  }
}
</style>
