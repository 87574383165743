<template>
  <mcr-button-router-link
    v-if="isButton"
    :label="label"
    :to="subscriptionPlansRoute"
    rel="nofollow"
    @click="onClick"
  ></mcr-button-router-link>
  <router-link v-else :to="subscriptionPlansRoute" rel="nofollow">
    <span @click="onClick" @click.ctrl="onClick" @click.meta="onClick" @click.middle="onClick">
      <slot>{{ label }}</slot>
    </span>
  </router-link>
</template>

<script>
import mcrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';

export default {
  props: {
    label: {type: String, default: 'Get Full Access'},
    isButton: {type: Boolean, default: true},
    recordTitle: {type: String, default: null},
    fromHints: {type: Boolean, default: false},
    onClick: {type: Function, default: () => {}},
    redirect: {type: String},
  },
  computed: {
    subscriptionPlansRoute() {
      const redirect = this.redirect === undefined ? this.$route.fullPath : this.redirect;
      return getSubscriptionWallRoute(redirect, this.recordTitle, this.fromHints);
    },
  },
  name: 'SubscriptionPlansLink',
  components: {mcrButtonRouterLink},
};
</script>

<style scoped></style>
