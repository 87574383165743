<script>
import FemalePersonIcon from '@/base/icons/person/FemalePersonIcon';
import MalePersonIcon from '@/base/icons/person/MalePersonIcon';
import UnknownPersonIcon from '@/base/icons/person/UnknownPersonIcon';

export default {
  functional: true,
  render: function (createElement, context) {
    const iconsByGender = {
      m: MalePersonIcon,
      f: FemalePersonIcon,
    };

    return createElement(iconsByGender[context.props.gender] || UnknownPersonIcon, context.data, context.children);
  },
};
</script>
