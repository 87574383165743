<template>
  <div class="family-tree-selector">
    <multiselect
      class="family-tree-multiselect"
      :class="{'display-inline': displayInline}"
      :value="selectedTree"
      :options="treesList"
      :allow-empty="false"
      label="name"
      trackBy="object_id"
      :group-values="groupValues"
      :group-label="groupLabel"
      :group-select="false"
      placeholder=""
      selected-label="SELECTED"
      deselect-label="SELECTED"
      select-label=""
      @open="onOpen"
      @select="onSelect"
    >
      <template v-slot:option="params">
        <span class="option-label" v-if="params.option.$isLabel">
          <span class="tree-name">{{ params.option.$groupLabel }}</span>
        </span>
        <span class="option-label" v-else>
          <span class="tree-name">{{ params.option.name }}</span
          ><span class="access" v-if="!params.option.is_owner">({{ getTreeAccess(params.option) }})</span>
          <component :is="params.option.icon" :size="18" v-if="params.option.icon" class="right-side"></component>
          <span class="right-side text-sm" v-if="shouldDisplayHintsCount(params.option)">{{
            getHintsCountLabel(params.option)
          }}</span>
        </span>
      </template>
      <span slot="noResult" class="input-helper">No elements found.</span>
    </multiselect>
  </div>
</template>

<script>
import CogIcon from 'vue-material-design-icons/Cog';
import Multiselect from 'vue-multiselect';

const MANAGE_ID = 'manage';

export default {
  props: {
    list: Array,
    treeId: Number,
    treeName: String,
    displayInline: {type: Boolean, default: false},
    onlyOwned: {type: Boolean, default: false},
    showManage: {type: Boolean, default: true},
    withHints: {type: Boolean, default: false},
  },
  watch: {
    treeId(value) {
      if (this.selectedTree.object_id != value) {
        this.resetSelectedTree();
      }
    },
    treeName(value) {
      if (this.selectedTree.name != value) {
        this.resetSelectedTree();
      }
    },
  },
  data() {
    const treeInList = this.list.find(tree => tree.object_id === this.treeId);
    return {
      selectedTree: treeInList || {
        name: this.treeName,
        object_id: this.treeId,
      },
    };
  },
  mounted() {
    this.$emit('mounted');
  },
  computed: {
    groupValues() {
      return this.showTreesGrouped ? 'trees' : null;
    },
    groupLabel() {
      return this.showTreesGrouped ? 'group' : null;
    },
    showTreesGrouped() {
      return this.list && this.list.length && (this.list.some(tree => !tree.is_owner) || !this.selectedTreeInList);
    },
    selectedTreeInList() {
      return this.list.find(tree => tree.object_id === this.treeId);
    },
    showManageItem() {
      if (!this.$store.getters.userIsLoggedInState) {
        return false;
      }
      return this.showManage;
    },
    treesList() {
      const manageTreesItem = {object_id: MANAGE_ID, name: 'Manage Trees', is_owner: true, icon: 'cog-icon'};
      if (this.showTreesGrouped) {
        const yourTrees = this.showManageItem ? [...this.ownedTrees, manageTreesItem] : this.ownedTrees;
        return [
          {group: 'Your trees', trees: yourTrees},
          {group: 'Shared with you', trees: this.sharedTrees},
        ];
      }

      if (this.selectedTreeInList) {
        return this.showManageItem ? [...this.list, manageTreesItem] : this.list;
      }
      const trees = [this.selectedTree, ...this.list];
      return this.showManageItem ? [...trees, manageTreesItem] : trees;
    },
    ownedTrees() {
      return this.list.filter(tree => tree.is_owner);
    },
    sharedTrees() {
      const list = this.onlyOwned ? [] : this.list.filter(tree => !tree.is_owner);
      return this.selectedTreeInList ? list : [this.selectedTree, ...list];
    },
  },
  methods: {
    onSelect(value) {
      if (value.object_id === MANAGE_ID) {
        this.$router.push({name: 'familytree-manage-all'});
        return;
      }
      if (value.object_id !== this.treeId) {
        this.selectedTree = value;
        this.$emit('select', value);
      }
    },
    onOpen() {
      this.$store.commit('setOnceShowFindOwnedTreeTooltipState', false);
    },
    getTreeAccess(tree) {
      return tree.is_editor ? 'Editor' : this.list.some(t => t.object_id === tree.object_id) ? 'Viewer' : 'Public';
    },
    resetSelectedTree() {
      const treeInList = this.list.find(tree => tree.object_id === this.treeId);
      this.selectedTree = treeInList || {
        name: this.treeName,
        object_id: this.treeId,
      };
    },
    shouldDisplayHintsCount(option) {
      return this.withHints && option.hints_count && option.object_id !== this.selectedTree.object_id;
    },
    getHintsCountLabel(option) {
      return option.hints_count === 1 ? `${option.hints_count} Record` : `${option.hints_count} Records`;
    },
  },
  components: {
    Multiselect,
    CogIcon,
  },
  name: 'familyTreeSelector',
};
</script>

<style lang="scss" scoped>
.family-tree-multiselect::v-deep {
  transition: all 0.2s ease;
  color: $text-color;
  min-height: 0;

  .multiselect__select {
    height: 34px;
    right: 2px;
    width: 30px;
  }
  .multiselect__select::before {
    top: 19px;
  }
  .multiselect__tags {
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 36px;
    min-height: 36px;
    padding-right: 30px;
    border: 1px solid $border-color-light;
    box-shadow: $box-shadow-extra-light;
  }
  .multiselect__single {
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 1px;
  }
  .multiselect__input {
    font-size: 18px;
    min-height: 22px;
  }
  .multiselect__content-wrapper {
    border: 1px solid $border-color-light;
    border-top: none;
    box-shadow: $box-shadow-extra-light;
  }
  .multiselect__option {
    min-height: 36px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: unset;
    &.multiselect__option--highlight {
      background: $mcr-red;
    }

    .access {
      font-weight: normal;
      color: $neutral-400;
      margin-left: 5px;
    }

    .right-side {
      position: absolute;
      right: 20px;
      top: 8px;
      color: $neutral-400;
    }
  }
  .multiselect__option.multiselect__option--highlight {
    .right-side {
      color: white;
      opacity: 0.5;
    }
  }
  .multiselect__option:after {
    position: relative;
    line-height: unset;
    margin-top: 1px;
  }
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input,
  .multiselect__option,
  .input-helper {
    background: $background-alternate-color;
  }

  .multiselect__content {
    li:not(.multiselect__element) {
      padding: 0;
    }
  }

  &.multiselect--disabled {
    .multiselect__tags,
    .multiselect__single,
    .multiselect__input,
    .multiselect__option,
    .input-helper,
    .multiselect__select {
      background: $background-color;
    }
  }

  &.display-inline {
    &:not(.multiselect--active) {
      .multiselect__tags,
      .multiselect__single,
      .multiselect__input,
      .input-helper {
        border: none;
        color: $text-alternate-color;
        background: $background-color;
        box-shadow: none;
        padding-left: 0;
      }
    }
  }
}
</style>
