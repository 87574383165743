<template>
  <div class="cny-banner">
    <div class="cny-link">
      <img :src="this.$getAsset('/assets/promos/mid-autumn-banner.png')" class="cny-banner-img" />
    </div>
    <div class="readable_content">
      <cny-discount-notice />
    </div>
  </div>
</template>

<script>
import cnyDiscountNotice from '@/components/common/promotions/cnyDiscountNotice';

export default {
  components: {cnyDiscountNotice},
};
</script>

<style scoped lang="scss">
.cny-banner {
  background-color: $power-red;
  display: flex;
  justify-content: center;
  .readable_content {
    display: none;
  }
  .cny-banner-img {
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    background: transparent;
    .cny-banner-img {
      display: none;
    }
    .readable_content {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 0;
    }
  }
}
</style>
