<template>
  <div class="search-within-select">
    <multiselect
      :value="valueObj"
      ref="level-input"
      placeholder=""
      :searchable="true"
      :internal-search="true"
      :preserveSearch="false"
      :clearOnSelect="false"
      :show-no-options="true"
      :show-labels="showLabels"
      :hide-selected="false"
      :options="options"
      :track-by="valueField"
      label="name"
      :openDirection="openDirection"
      selectLabel=""
      deselectLabel="Selected"
      :disabled="disabled"
      class="bordered"
      :class="multiselectClasses"
      @select="onSelect"
    >
      <close-icon
        v-if="
          (valueObj[valueField] || valueObj[valueField] === 0) && !isMultiselectOpened() && !disabled && allowRemove
        "
        slot="caret"
        @mousedown.prevent.stop="clear()"
        class="caret-icon"
      ></close-icon>
      <span slot="noResult" class="input-helper">Not found</span>
      <span slot="singleLabel" slot-scope="props">
        <template>{{ props.option.name || placeholder }}</template>
      </span>
    </multiselect>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: [String, Number],
    valueField: {type: String, default: 'value'},
    placeholder: String,
    multiselectClasses: String,
    options: Array,
    disabled: {type: Boolean, default: false},
    allowRemove: {type: Boolean, default: true},
    showLabels: {type: Boolean, default: false},
    openDirection: {type: String, default: ''},
  },
  computed: {
    valueObj() {
      return this.options.find(item => item[this.valueField] == this.value) || {};
    },
  },
  methods: {
    onSelect(option) {
      const value = option ? option[this.valueField] : null;
      this.$emit('select', value);
    },
    isMultiselectOpened() {
      return this.$refs['level-input'] && this.$refs['level-input'].isOpen;
    },
    clear() {
      this.onSelect(null);
    },
  },
  components: {Multiselect, CloseIcon},
};
</script>

<style scoped></style>
