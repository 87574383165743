<template>
  <images-gallery-overlay-container
    class="person-photos-gallery-overlay"
    @image-loaded="onImageLoad"
    @display-item-changed="onDisplayItemChanged"
  >
    <template v-slot:absolute-actions="props">
      <div class="mobile-title">
        <div class="heading-6 text-md-mobile main-title">Media of {{ personName }}</div>
        <div class="photo-count text-md-mobile">{{ props.currentIndex + 1 }} of {{ totalCount }} photos</div>
        <div class="photo-count">{{ sourceName }} (Page {{ props.item.priority }})</div>
      </div>
    </template>
    <template v-slot:info-bar="props">
      <div class="info-bar-content">
        <div class="heading-6 main-title">Images of {{ personName }}</div>
        <p class="photo-count">{{ props.currentIndex + 1 }} of {{ totalCount }} images</p>
        <template v-if="props.item.priority">
          <div class="photo-count">{{ sourceName }} (Page {{ props.item.priority }})</div>
          <div class="info-bar-actions">
            <div class="icon-action">
              <router-link class="icon-container" :to="getViewerRoute(props.item.priority, props.item.id)">
                <view-icon :size="24"></view-icon>
              </router-link>
              <div class="label text-sm">Open Source in Viewer</div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </images-gallery-overlay-container>
</template>

<script>
import ImagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import {preload} from '@common/utils/utils.sources';
import ViewIcon from 'vue-material-design-icons/FolderMoveOutline';
import {mapGetters} from 'vuex';

export default {
  props: {
    getNextPage: Function,
  },
  computed: {
    ...mapGetters(['galleryMetaState']),
    personName() {
      return this.galleryMetaState.personName;
    },
    sourceName() {
      return this.galleryMetaState.sourceName;
    },
    totalCount() {
      return this.galleryMetaState.totalCount;
    },
  },
  methods: {
    onDisplayItemChanged(newItem) {
      if (newItem.isLoading) {
        this.getNextPage();
      }
    },
    onImageLoad() {
      if (this.$store.getters.galleryHasNextItemState) {
        const src = this.$store.getters.galleryItemsState[this.$store.getters.galleryCurrentIndexState + 1].src;
        preload([src]);
      }
    },
    getViewerRoute(priority, id) {
      return this.$store.getters.galleryMetaState.getViewerRoute(priority, id);
    },
  },
  name: 'MentionsImagesGalleryOverlayContainer',
  components: {ImagesGalleryOverlayContainer, ViewIcon},
};
</script>

<style lang="scss" scoped>
@import '@common/style/gallery.info';
.photo-count {
  word-break: break-word;
}
</style>
