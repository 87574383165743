<template>
  <a class="discord-join-button" :href="discordLink" target="_blank">
    <span>{{ label }}</span>
    <discord-icon :size="20"></discord-icon>
  </a>
</template>

<script>
import DiscordIcon from 'vue-material-design-icons/Discord';

export default {
  props: {
    label: {type: String, default: 'Join our Chinese Genealogy Discord Community'},
    showIcon: {type: Boolean, default: true},
  },
  computed: {
    discordLink() {
      return process.env.VUE_APP_DISCORD_INVITE_LINK;
    },
  },
  components: {DiscordIcon},
  name: 'DiscordJoinButton',
};
</script>

<style lang="scss" scoped>
a.discord-join-button {
  display: flex;
  align-items: center;

  .discord-icon {
    margin-left: 5px;
  }
}
</style>
