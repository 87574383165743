<template>
  <svg
    enable-background="new 0 0 50 50"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    width="50px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="person-icon"
  >
    <path
      class="shape"
      d="M30.933,32.528  c-0.026-0.287-0.045-0.748-0.06-1.226c4.345-0.445,7.393-1.487,7.393-2.701c-0.012-0.002-0.011-0.05-0.011-0.07  c-3.248-2.927,2.816-23.728-8.473-23.306c-0.709-0.6-1.95-1.133-3.73-1.133c-15.291,1.157-8.53,20.8-12.014,24.508  c-0.002,0.001-0.005,0.001-0.007,0.001c0,0.002,0.001,0.004,0.001,0.006c0,0.001-0.001,0.002-0.001,0.002s0.001,0,0.002,0.001  c0.014,1.189,2.959,2.212,7.178,2.668c-0.012,0.29-0.037,0.649-0.092,1.25C19.367,37.238,7.546,35.916,7,45h38  C44.455,35.916,32.685,37.238,30.933,32.528z"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'FemalePersonIcon',
};
</script>

<style scoped></style>
